"use client";

import React from "react";
import * as Select from "@radix-ui/react-select";
import clsx from "clsx";
import For from "@/components/common/For";
import styles from "./Select.module.scss";
import { ChevronDown, ChevronUp, CheckIcon } from "lucide-react";
interface SelectProps {
  options: {
    value: string;
    label: string;
    disabled?: boolean;
  }[];
  value?: string;
  name: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
}
interface SelectItemProps {
  children: React.ReactNode;
  className?: string;
}

/**
 *
 * Select
 *
 */
const SelectInput = ({
  options = [],
  value = "",
  name = "",
  onChange = _ => {},
  className = "",
  placeholder = "",
  ...props
}: SelectProps) => {
  return <div className={`${styles.select}`} {...props} data-sentry-component="SelectInput" data-sentry-source-file="Select.tsx">
      <Select.Root name={name} onValueChange={value => onChange(value)} className="w-[200px]" data-sentry-element="unknown" data-sentry-source-file="Select.tsx">
        <Select.Trigger className={`rounded-button border border-border-color text-border-color w-full h-[50px] flex gap-6 justify-between items-center text-left px-4 min-w-[200px] max-w-full whitespace-nowrap leading-none select overflow-hidden ${className}`} data-sentry-element="unknown" data-sentry-source-file="Select.tsx">
          <Select.Value placeholder={placeholder} className="text-left whitespace-nowrap" data-sentry-element="unknown" data-sentry-source-file="Select.tsx" />
          <Select.Icon color="#ff0000" data-sentry-element="unknown" data-sentry-source-file="Select.tsx" />
        </Select.Trigger>

        <Select.Portal data-sentry-element="unknown" data-sentry-source-file="Select.tsx">
          <Select.Content position="item-aligned" className="overflow-auto overscroll-contain bg-white rounded mt-20 py-2 w-full max-w-full shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] z-[100000]" data-sentry-element="unknown" data-sentry-source-file="Select.tsx">
            <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-orange cursor-default" data-sentry-element="unknown" data-sentry-source-file="Select.tsx">
              <ChevronUp width={35} data-sentry-element="ChevronUp" data-sentry-source-file="Select.tsx" />
            </Select.ScrollUpButton>
            <Select.Viewport className="p-[5px] overflow-auto overscroll-contain" data-sentry-element="unknown" data-sentry-source-file="Select.tsx">
              <For each={options} render={item => {
              return <SelectItem key={item.value} value={item.value} disabled={item.disabled}>
                      {item.label}
                    </SelectItem>;
            }} data-sentry-element="For" data-sentry-source-file="Select.tsx" />
            </Select.Viewport>
            <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-orange cursor-default" data-sentry-element="unknown" data-sentry-source-file="Select.tsx">
              <ChevronDown width={35} data-sentry-element="ChevronDown" data-sentry-source-file="Select.tsx" />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>;
};
const SelectItem = React.forwardRef(({
  className = "",
  children,
  ...props
}: SelectItemProps, forwardedRef) => {
  return <Select.Item className={clsx("leading-none py-4 px-6 cursor-pointer hover:bg-gold transition-colors hover:text-black ease-in-out duration-200 relative", className)} {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute left-[2px] top-[50%] -translate-y-1/2 w-[20px] inline-flex items-center justify-center">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>;
});
export default SelectInput;