import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.3_next@15.1.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@_tzymazodpvcjia2dzkvwd3h6ui/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.3_next@15.1.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@_tzymazodpvcjia2dzkvwd3h6ui/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.3_next@15.1.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@_tzymazodpvcjia2dzkvwd3h6ui/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.83.1/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.83.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/Clarity.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/SmoothScrolling.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Details/Details.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ui/Nav/Nav.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/Footer/Footer.module.scss");
