"use client";

import React, { useState } from "react";
import styles from "./Details.module.scss";
import * as Accordion from "@radix-ui/react-accordion";
import { Plus } from "lucide-react";
import If from "@/components/common/If";
import Link from "next/link";
import RichText from "@/components/common/RichText";

/**
 *
 * Details
 *
 */
const Details = ({
  items,
  isSub = false,
  ...props
}) => {
  const [currentOpen, setCurrentOpen] = useState(null);
  return <section className={styles.details} {...props} data-sentry-component="Details" data-sentry-source-file="Details.tsx">
      <Accordion.Root className="w-full" collapsible onValueChange={value => {
      setCurrentOpen(value);
    }} data-sentry-element="unknown" data-sentry-source-file="Details.tsx">
        {items.map((item, index) => !item.link || item.link === "" ? <Accordion.Item key={item.title} value={item.title} className={`pt-4 pb-3 w-full block ${isSub ? "" : "border-b border-b-white"}`}>
              <Accordion.Header>
                <Accordion.Trigger className={`stack gap-2 w-full pb-1 ${styles.trigger}`}>
                  <div className="flex justify-between items-center w-full">
                    <If condition={item.link}>
                      <div className="flex gap-2 items-center">
                        <Link href={item.link} target={item.external ? "_blank" : "_self"}>
                          <h2 className="text-[1.25rem] font-hero text-current" dangerouslySetInnerHTML={{
                      __html: item.title
                    }} />
                        </Link>
                        <If condition={item.icon}>
                          <span className="text-sm font-thin text-white text-left">
                            {item.icon}
                          </span>
                        </If>
                      </div>
                      <svg className="mr-2" width="15" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.25" y1="0.566987" x2="26.5116" y2="15.1518" stroke="#EAE8E4" />
                        <line x1="0.832031" y1="29.567" x2="26.0936" y2="14.9822" stroke="#EAE8E4" />
                      </svg>
                    </If>
                    <If condition={!item.link}>
                      <h2 className="text-[1.25rem] font-hero text-current" dangerouslySetInnerHTML={{
                  __html: item.title
                }} />
                      <Plus className={`transtion-all ease-in-out duration-300 ${currentOpen === item.title ? "rotate-45" : ""} relative block`} size={32} color="white" strokeWidth={0.5} />
                    </If>
                  </div>
                  <If condition={item.detail}>
                    <span className="text-sm font-thin text-white text-left">
                      {item.detail}
                    </span>
                  </If>
                </Accordion.Trigger>
              </Accordion.Header>
              <If condition={!item.link}>
                <Accordion.Content className={`
                              overflow-hidden transition-all duration-300 ease-in-out
                              data-[state=open]:animate-slideDown
                              data-[state=closed]:animate-slideUp
                              ${styles.content}
                            `}>
                  <div className="py-4">
                    {item.description || item.children}
                    {item.content?.json && <RichText json={item.content.json} />}
                  </div>
                </Accordion.Content>
              </If>
            </Accordion.Item> : <Link href={item.link}>
              <div className="border-b border-b-white pt-4 pb-4 w-full block">
                <div className="flex justify-between items-center w-full">
                  <h2 className="text-[1.25rem] font-hero text-current" dangerouslySetInnerHTML={{
              __html: item.title
            }} />
                  <svg width="15" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                    <line x1="1.25" y1="0.566987" x2="26.5116" y2="15.1518" stroke="#EAE8E4" />
                    <line x1="0.832031" y1="29.567" x2="26.0936" y2="14.9822" stroke="#EAE8E4" />
                  </svg>
                </div>
              </div>
            </Link>)}
      </Accordion.Root>
    </section>;
};
export default Details;