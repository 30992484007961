"use client";

import { useCallback, useContext } from "react";
import { useState } from "react";
import { createContext, ReactNode } from "react";
interface SwatchesContextType {
  selectedSwatch?: any | null;
  setSelectedSwatch: (arg0: any) => void;
  allSwatches?: any[];
  setAllSwatches: (arg0: any[]) => void;
  nextSwatch?: () => void;
  prevSwatch?: () => void;
  getSelectedSwatch?: () => any;
  swatchFilters?: {
    colorType: string;
    species: string;
    legacy?: string | "legacy" | "new" | "all";
  };
  setSwatchFilters?: (arg0: {
    colorType: string;
    species: string;
    legacy?: string | "legacy" | "new" | "all";
  }) => void;
}
export const SwatchesContext = createContext<SwatchesContextType>({
  selectedSwatch: null,
  setSelectedSwatch: () => {},
  allSwatches: [],
  setAllSwatches: () => {},
  nextSwatch: () => {},
  prevSwatch: () => {},
  getSelectedSwatch: () => {},
  swatchFilters: {
    colorType: "All",
    species: "All",
    legacy: "legacy"
  },
  setSwatchFilters: () => {}
});
interface SwatchesProviderProps {
  children: ReactNode;
  swatches?: any[];
}
export const SwatchesContextProvider = SwatchesContext.Provider;
export function SwatchesProvider({
  children,
  swatches = []
}: SwatchesProviderProps) {
  const [selectedSwatch, setSelectedSwatch] = useState(null);
  const [allSwatches, setAllSwatches] = useState(swatches);
  const [swatchFilters, setSwatchFilters] = useState({
    colorType: "All",
    species: "All",
    legacy: "legacy"
  });
  const nextSwatch = useCallback(ev => {
    ev.stopPropagation();
    const currentIndex = allSwatches.findIndex(swatch => swatch?.sys?.id === selectedSwatch?.sys?.id);
    const nextIndex = (currentIndex + 1) % allSwatches.length;
    console.log("nextIndex", selectedSwatch);
    setSelectedSwatch(allSwatches[nextIndex]);
  }, [selectedSwatch, allSwatches]);
  const prevSwatch = useCallback(ev => {
    ev.stopPropagation();
    const currentIndex = allSwatches.findIndex(swatch => swatch?.sys?.id === selectedSwatch?.sys?.id);
    const prevIndex = (currentIndex - 1 + allSwatches.length) % allSwatches.length;
    setSelectedSwatch(allSwatches[prevIndex]);
  }, [selectedSwatch, allSwatches]);
  const getSelectedSwatch = () => {
    // const swatch = allSwatches[selectedSwatch];
    // if (swatch) {
    //   return swatch;
    // }
    return selectedSwatch;
  };
  return <SwatchesContextProvider value={{
    selectedSwatch,
    setSelectedSwatch,
    allSwatches,
    setAllSwatches,
    nextSwatch,
    prevSwatch,
    getSelectedSwatch,
    swatchFilters,
    setSwatchFilters
  }} data-sentry-element="SwatchesContextProvider" data-sentry-component="SwatchesProvider" data-sentry-source-file="Swatches.tsx">
      {children}
    </SwatchesContextProvider>;
}
export function useSwatches() {
  const context = useContext(SwatchesContext);
  if (context === undefined) {
    throw new Error("useSwatches must be used within a SwatchesProvider");
  }
  return context;
}